'use client'
import React, { useEffect, useState, useMemo, useRef, useLayoutEffect } from 'react'
import Link from 'components/Link'
import Text from 'components/Text'
import classNames from 'classnames'
import { usePathname } from 'next/navigation'
import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import debounce from 'lodash/debounce'

import { getCookie } from 'helpers/cookies'

import { FindJobsSvg } from 'images/svg'
import { ReactComponent as RemoteJobsSvg } from 'images/icons/remoteJobs.svg'
import { ReactComponent as OnsiteJobsSvg } from 'images/icons/onSiteJobs.svg'
import { ReactComponent as RecommendedJobsSvg } from 'images/icons/recommendedJobs.svg'
import { ReactComponent as RecommendedJobsSvgOn } from 'images/icons/recommendedJobs_on.svg'
import { ReactComponent as RemoteJobsSvgOn } from 'images/icons/remoteJobs_on.svg'
import { ReactComponent as OnsiteJobsSvgOn } from 'images/icons/onSiteJobs_on.svg'
import { ReactComponent as Web3Svg } from 'images/icons/web3.svg'
import { ReactComponent as Web3SvgOn } from 'images/icons/web3_on.svg'
import { ReactComponent as QuestionCircleSvg } from 'images/icons/questionCircle.svg'
import { ReactComponent as QuestionCircleSvgOn } from 'images/icons/questionCircle_on.svg'
import { ReactComponent as CareerGuideSvg } from 'images/icons/careerGuide.svg'
import { ReactComponent as CareerGuideSvgOn } from 'images/icons/careerGuide_on.svg'
import { ReactComponent as MoreSvg } from '../imgs/svg/more.svg'
import { ReactComponent as ArrowSvg } from 'images/icons/arrow.svg'

import styles from '../Header.module.scss'
import { getCountryKey } from 'helpers/country'

interface IProps {
  langKey: string
  lang: any
}

const NavLeft = (props: IProps) => {
  const { langKey, lang } = props
  const pathname = usePathname()
  const isLogin = getCookie('sessionid') ? true : false
  const [countryKey, setCountryKey] = useState('ph')
  const [currentMenu, setCurrentMenu] = useState('')
  const [currentHoverMenu, setCurrentHoverMenu] = useState('')

  const [overflowLinks, setOverflowLinks] = useState([])
  const [showLinkIds, setShowLinkIds] = useState([
    'home',
    'find-jobs',
    'companies',
    'web3-landing-page',
    'remote-jobs',
    'start-guide'
  ])
  // const [showLinkIds, setShowLinkIds] = useState([])
  const headerLinksRef = useRef<HTMLUListElement>(null)

  const RIf = (props: { show: boolean; children: any }) => {
    return props.show ? props.children : null
  }

  useEffect(() => {
    const countryKey = getCountryKey()
    setCountryKey(countryKey)
  }, [])

  useEffect(() => {
    const calculateVisibleLinks = () => {
      setTimeout(() => {
        if (!headerLinksRef.current) {
          return
        }
        const containerWidth = headerLinksRef.current?.offsetWidth // 获取容器宽度
        const links = headerLinksRef.current?.querySelectorAll('li')
        let totalWidth = 0
        let overflowLinks = []
        const showLinkIds = []
        links.forEach((link, index) => {
          const idValue = link.id
          const linkWidth = (link as any).getBoundingClientRect().width
          if (totalWidth + linkWidth < containerWidth) {
            totalWidth += linkWidth
            showLinkIds.push(idValue)
          } else {
            const filterItems = moreItems.filter((item) => item.id === idValue)
            overflowLinks = overflowLinks.concat(filterItems)
          }
        })
        setOverflowLinks(overflowLinks)
        setShowLinkIds(showLinkIds)
      }, 0)
    }

    const debouncedCalculateVisibleLinks = debounce(calculateVisibleLinks, 100)

    calculateVisibleLinks() // 初次计算
    window.addEventListener('resize', debouncedCalculateVisibleLinks)

    return () => {
      window.removeEventListener('resize', debouncedCalculateVisibleLinks)
      debouncedCalculateVisibleLinks.cancel() // 确保防抖逻辑清理干净
    }
  }, [])

  const {
    home,
    careerGuide,
    companies,
    findJobs,
    remoteJobs,
    onSiteJobs,
    recommendedJobs,
    workInAnyCountryOrRegion,
    jobsInYourCountryOrRegion,
    aiRecommendsJobsBasedOnYourPreferences,
    whyBossJob,
    startGuide,
    Web3Jobs,
    Web3Describe,
    web3LandingPage
  } = lang || {}

  const moreItems = [
    {
      id: 'remote-jobs',
      key: 'remote-jobs',
      label: (
        <Link title='RemoteJobs' to={'/' + langKey + '/remote-jobs-hiring/job-search'}>
          <div className={styles.children_nav}>
            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}>{remoteJobs}</div>
            </div>
          </div>
        </Link>
      )
    },
    {
      id: 'start-guide',
      key: 'talent',
      label: (
        <Link title='' to={'/' + langKey + (countryKey === 'jp' ? '/talent' : '/talents')} aTag>
          <div className={styles.children_nav}>
            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}> {startGuide}</div>
            </div>
          </div>
        </Link>
      )
    },
    {
      id: 'start-guide',
      key: 'careerGuide',
      label: (
        <Link
          title='Career Guide'
          to={countryKey === 'jp' ? 'https://bossjob.jp/blog' : 'https://blog.bossjob.ph'}
        >
          <div className={styles.children_nav}>
            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}> {careerGuide}</div>
            </div>
          </div>
        </Link>
      )
    },
    {
      id: 'companies',
      key: 'companies',
      label: (
        <Link title='Companies' to={'/' + langKey + '/companies'}>
          <div className={styles.children_nav}>
            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}> {companies}</div>
            </div>
          </div>
        </Link>
      )
    },
    {
      id: 'find-jobs',
      key: 'remote-jobs-hiring',
      label: (
        <Link title='RemoteJobs' to={'/' + langKey + '/remote-jobs-hiring/job-search'}>
          <div className={styles.children_nav}>
            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}> {remoteJobs}</div>
            </div>
          </div>
        </Link>
      )
    },
    {
      id: 'find-jobs',
      key: 'onsite-jobs',
      label: (
        <Link title='OnSiteJobs' to={'/' + langKey + '/jobs-hiring/job-search'}>
          <div className={styles.children_nav}>
            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}> {onSiteJobs}</div>
            </div>
          </div>
        </Link>
      )
    },
    {
      id: 'find-jobs',
      key: 'web3-jobs',
      label: (
        <Link title='web3Jobs' to={'/' + langKey + '/web3-jobs-hiring/job-search'}>
          <div className={styles.children_nav}>
            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}> {Web3Jobs}</div>
            </div>
          </div>
        </Link>
      )
    },
    {
      id: 'find-jobs',
      key: 'web3-jobs',
      label: (
        <Link title='web3Jobs' to={'/' + langKey + '/web3-jobs-hiring/job-search'}>
          <div className={styles.children_nav}>
            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}> {Web3Jobs}</div>
            </div>
          </div>
        </Link>
      )
    },
    isLogin && {
      id: 'find-jobs',
      key: 'recommended-jobs',
      label: (
        <Link title='RecommendedJobs' to={'/' + langKey + '/my-jobs?page=1'}>
          <div className={styles.children_nav}>
            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}> {recommendedJobs}</div>
            </div>
          </div>
        </Link>
      )
    },
    {
      id: 'home',
      key: 'home',
      label: (
        <Link title='Home' to={'/' + langKey}>
          <div className={styles.children_nav}>
            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}> {home}</div>
            </div>
          </div>
        </Link>
      )
    }
  ].filter(Boolean)

  useEffect(() => {
    const countryKey = getCountryKey()
    setCountryKey(countryKey)
  }, [])

  useEffect(() => {
    if (/(.*)\/my-jobs(.*)$/.test(pathname)) {
      setCurrentMenu('recommended')
    } else if (/(.*)\/jobs-hiring(.*)$/.test(pathname)) {
      setCurrentMenu('onsite')
    } else if (/(.*)\/remote-jobs-hiring(.*)$/.test(pathname)) {
      setCurrentMenu('remote')
    } else if (/(.*)\/web3-jobs-hiring(.*)$/.test(pathname)) {
      setCurrentMenu('web3')
    } else {
      setCurrentMenu('')
    }
  }, [pathname])

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link
          title='RemoteJobs'
          to={'/' + langKey + '/remote-jobs-hiring/job-search'}
          {...(pathname.endsWith('/remote-jobs') && {
            'data-gtag-event': 'rl_active_find_jobs_remote'
          })}
        >
          <div className={styles.children_nav}>
            <div className={styles.children_nav_icon}>
              <RIf show={currentMenu === 'remote'}>
                <RemoteJobsSvgOn />
              </RIf>
              <RIf show={currentMenu !== 'remote'}>
                <RemoteJobsSvgOn className={styles.icon_hover} />
                <RemoteJobsSvg className={styles.icon_default} />
              </RIf>
            </div>

            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}>{remoteJobs}</div>
            </div>
          </div>
        </Link>
      )
    },
    {
      key: '2',
      label: (
        <Link
          title='OnSiteJobs'
          to={'/' + langKey + '/jobs-hiring/job-search'}
          {...(pathname.endsWith('/remote-jobs') && {
            'data-gtag-event': 'rl_active_find_jobs_onsite'
          })}
        >
          <div className={styles.children_nav}>
            <div className={styles.children_nav_icon}>
              <RIf show={currentMenu === 'onsite'}>
                <OnsiteJobsSvgOn />
              </RIf>
              <RIf show={currentMenu !== 'onsite'}>
                <OnsiteJobsSvgOn className={styles.icon_hover} />
                <OnsiteJobsSvg className={styles.icon_default} />
              </RIf>
            </div>

            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}>{onSiteJobs}</div>
            </div>
          </div>
        </Link>
      )
    },
    {
      key: '4',
      label: (
        <Link
          title='web3Jobs'
          to={'/' + langKey + '/web3-jobs-hiring/job-search'}
          {...(pathname.endsWith('/remote-jobs') && {
            'data-gtag-event': 'rl_active_find_jobs_web3'
          })}
        >
          <div className={styles.children_nav}>
            <div className={styles.children_nav_icon}>
              <RIf show={currentMenu === 'web3'}>
                <Web3SvgOn />
              </RIf>
              <RIf show={currentMenu !== 'web3'}>
                <Web3SvgOn className={styles.icon_hover} />
                <Web3Svg className={styles.icon_default} />
              </RIf>
            </div>

            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}>{Web3Jobs}</div>
            </div>
          </div>
        </Link>
      )
    },
    isLogin && {
      key: '3',
      label: (
        <Link
          title='RecommendedJobs'
          to={'/' + langKey + '/my-jobs?page=1'}
          {...(pathname.endsWith('/remote-jobs') && {
            'data-gtag-event': 'rl_active_find_jobs_recommend'
          })}
        >
          <div className={styles.children_nav}>
            <div className={styles.children_nav_icon}>
              <RIf show={currentMenu === 'recommended'}>
                <RecommendedJobsSvgOn />
              </RIf>
              <RIf show={currentMenu !== 'recommended'}>
                <RecommendedJobsSvgOn className={styles.icon_hover} />
                <RecommendedJobsSvg className={styles.icon_default} />
              </RIf>
            </div>

            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}>{recommendedJobs}</div>
            </div>
          </div>
        </Link>
      )
    }
  ].filter(Boolean)

  const blogLinkList = {
    default: 'https://blog.bossjob.ph',
    // nation
    jp: 'https://bossjob.jp/blog',
    ph: 'https://bossjob.ph/blog',
    sg: 'https://bossjob.sg/blog'
  }

  const items1: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link
          title='talents'
          to={'/' + langKey + (countryKey === 'jp' ? '/talent' : '/talents')}
          aTag
        >
          <div className={styles.children_nav}>
            <div className={styles.children_nav_icon}>
              <RIf show={currentMenu === 'talent'}>
                <QuestionCircleSvgOn />
              </RIf>
              <RIf show={currentMenu !== 'talent'}>
                <QuestionCircleSvgOn className={styles.icon_hover} />
                <QuestionCircleSvg className={styles.icon_default} />
              </RIf>
            </div>

            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}>{startGuide}</div>
            </div>
          </div>
        </Link>
      )
    },
    {
      key: '2',
      label: (
        <Link
          title='Career Guide'
          // to={countryKey === 'jp' ? 'https://bossjob.jp/blog' : 'https://blog.bossjob.ph'}
          to={blogLinkList[countryKey] ?? blogLinkList['default']}
        >
          <div className={styles.children_nav}>
            <div className={styles.children_nav_icon}>
              <RIf show={currentMenu === 'career-guide'}>
                <CareerGuideSvgOn />
              </RIf>
              <RIf show={currentMenu !== 'career-guide'}>
                <CareerGuideSvgOn className={styles.icon_hover} />
                <CareerGuideSvg className={styles.icon_default} />
              </RIf>
            </div>

            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}>{careerGuide}</div>
            </div>
          </div>
        </Link>
      )
    }
  ]

  const isShowLink = (id) => {
    return showLinkIds.includes(id)
  }

  return (
    <>
      <ul
        className={styles.headerLinksList}
        style={{ height: 0, visibility: 'hidden', flexWrap: 'wrap' }}
        ref={headerLinksRef}
      >
        <li
          id='home'
          className={styles.headerLink}
          {...(pathname.endsWith('/remote-jobs') && {
            'data-gtag-event': 'rl_active_home'
          })}
        >
          {pathname != '/' + langKey && pathname != '/' && pathname != '/en-us' ? (
            <Link title='Home' to={'/' + langKey}>
              <Text textStyle='lg' className={styles.headerLinkText}>
                {home}
              </Text>
            </Link>
          ) : (
            <Text
              textStyle='lg'
              className={classNames([styles.headerLinkText, styles.headerLinkTextCurrentPage])}
            >
              {home}
            </Text>
          )}
        </li>
        <li id='find-jobs' className={classNames([styles.headerLink])}>
          <Dropdown
            menu={{ items }}
            overlayClassName={classNames({
              [styles.findJobDownMenu]: true,
              [styles.remoteMenu]: currentMenu === 'remote',
              [styles.onsiteMenu]: currentMenu === 'onsite',
              [styles.web3Menu]: currentMenu === 'web3',
              [styles.recommendedMenu]: currentMenu === 'recommended'
            })}
          >
            <Text
              textStyle='lg'
              className={classNames({
                [styles.headerLinkText]: true,
                [styles.headerFindJob]: true,
                [styles.headerLinkCurrentPage]: [
                  'remote',
                  'onsite',
                  'web3',
                  'recommended'
                ].includes(currentMenu)
              })}
            >
              {findJobs}
              <FindJobsSvg />
            </Text>
          </Dropdown>
        </li>
        <li
          id='companies'
          className={styles.headerLink}
          {...(pathname.endsWith('/remote-jobs') && {
            'data-gtag-event': 'rl_active_company'
          })}
        >
          {!pathname.includes('/companies') ? (
            <Link title='Companies' to={'/' + langKey + '/companies'}>
              <Text textStyle='lg' className={styles.headerLinkText}>
                {companies}
              </Text>
            </Link>
          ) : (
            <Text
              textStyle='lg'
              className={classNames([styles.headerLinkText, styles.headerLinkTextCurrentPage])}
            >
              {companies}
            </Text>
          )}
        </li>

        <li id='web3-landing-page' className={styles.headerLink}>
          {!pathname.includes('/web3-landing-page') || pathname.includes('/web3-jobs-hiring') ? (
            <Link title='web3 landing page' to={'/' + langKey + '/web3-landing-page'}>
              <Text textStyle='lg' className={styles.headerLinkText}>
                {web3LandingPage || 'Web3 Jobs'}
              </Text>
            </Link>
          ) : (
            <Text
              textStyle='lg'
              className={classNames([styles.headerLinkText, styles.headerLinkTextCurrentPage])}
            >
              {web3LandingPage || 'Web3 Jobs'}
            </Text>
          )}
        </li>

        <li id='remote-jobs' className={styles.headerLink}>
          {!pathname.includes('/remote-jobs') || pathname.includes('/remote-jobs-hiring') ? (
            <Link title='remote Jobs' to={'/' + langKey + '/remote-jobs'}>
              <Text textStyle='lg' className={styles.headerLinkText}>
                {remoteJobs}
              </Text>
            </Link>
          ) : (
            <Text
              textStyle='lg'
              className={classNames([styles.headerLinkText, styles.headerLinkTextCurrentPage])}
            >
              {remoteJobs}
            </Text>
          )}
        </li>

        <li id='start-guide' className={classNames([styles.headerLink])}>
          <Dropdown
            menu={{ items: items1 }}
            overlayClassName={classNames({
              [styles.findJobDownMenu]: true
            })}
          >
            <Text
              textStyle='lg'
              className={classNames({
                [styles.headerLinkText]: true,
                [styles.headerFindJob]: true
              })}
            >
              {whyBossJob || 'About'}
              <FindJobsSvg />
            </Text>
          </Dropdown>
        </li>
      </ul>
      <ul className={styles.headerLinksList}>
        <RIf show={isShowLink('home')}>
          <li
            id='home'
            className={styles.headerLink}
            {...(pathname.endsWith('/remote-jobs') && {
              'data-gtag-event': 'rl_active_home'
            })}
          >
            {pathname != '/' + langKey && pathname != '/' && pathname != '/en-us' ? (
              <Link title='Home' to={'/' + langKey}>
                <Text textStyle='lg' className={styles.headerLinkText}>
                  {home}
                </Text>
              </Link>
            ) : (
              <Text
                textStyle='lg'
                className={classNames([styles.headerLinkText, styles.headerLinkTextCurrentPage])}
              >
                {home}
              </Text>
            )}
          </li>
        </RIf>
        <RIf show={isShowLink('find-jobs')}>
          <li id='find-jobs' className={classNames([styles.headerLink])}>
            <Dropdown
              menu={{ items }}
              overlayClassName={classNames({
                [styles.findJobDownMenu]: true,
                [styles.remoteMenu]: currentMenu === 'remote',
                [styles.onsiteMenu]: currentMenu === 'onsite',
                [styles.web3Menu]: currentMenu === 'web3',
                [styles.recommendedMenu]: currentMenu === 'recommended'
              })}
            >
              <Text
                textStyle='lg'
                className={classNames({
                  [styles.headerLinkText]: true,
                  [styles.headerFindJob]: true,
                  [styles.headerLinkCurrentPage]: [
                    'remote',
                    'onsite',
                    'web3',
                    'recommended'
                  ].includes(currentMenu)
                })}
              >
                {findJobs}
                <FindJobsSvg />
              </Text>
            </Dropdown>
          </li>
        </RIf>
        <RIf show={isShowLink('companies')}>
          <li
            id='companies'
            className={styles.headerLink}
            {...(pathname.endsWith('/remote-jobs') && {
              'data-gtag-event': 'rl_active_company'
            })}
          >
            {!pathname.includes('/companies') ? (
              <Link title='Companies' to={'/' + langKey + '/companies'}>
                <Text textStyle='lg' className={styles.headerLinkText}>
                  {companies}
                </Text>
              </Link>
            ) : (
              <Text
                textStyle='lg'
                className={classNames([styles.headerLinkText, styles.headerLinkTextCurrentPage])}
              >
                {companies}
              </Text>
            )}
          </li>
        </RIf>

        <RIf show={isShowLink('web3-landing-page')}>
          <li id='web3-landing-page' className={styles.headerLink}>
            {!pathname.includes('/web3-landing-page') || pathname.includes('/web3-jobs-hiring') ? (
              <Link title='web3 landing page' to={'/' + langKey + '/web3-landing-page'}>
                <Text textStyle='lg' className={styles.headerLinkText}>
                  {web3LandingPage || 'Web3 Jobs'}
                </Text>
              </Link>
            ) : (
              <Text
                textStyle='lg'
                className={classNames([styles.headerLinkText, styles.headerLinkTextCurrentPage])}
              >
                {web3LandingPage || 'Web3 Jobs'}
              </Text>
            )}
          </li>
        </RIf>

        <RIf show={isShowLink('remote-jobs')}>
          <li id='remote-jobs' className={styles.headerLink}>
            {!pathname.includes('/remote-jobs') || pathname.includes('/remote-jobs-hiring') ? (
              <Link title='remote Jobs' to={'/' + langKey + '/remote-jobs'}>
                <Text textStyle='lg' className={styles.headerLinkText}>
                  {remoteJobs}
                </Text>
              </Link>
            ) : (
              <Text
                textStyle='lg'
                className={classNames([styles.headerLinkText, styles.headerLinkTextCurrentPage])}
              >
                {remoteJobs}
              </Text>
            )}
          </li>
        </RIf>

        <RIf show={isShowLink('start-guide')}>
          <li id='start-guide' className={classNames([styles.headerLink])}>
            <Dropdown
              menu={{ items: items1 }}
              overlayClassName={classNames({
                [styles.findJobDownMenu]: true
              })}
            >
              <Text
                textStyle='lg'
                className={classNames({
                  [styles.headerLinkText]: true,
                  [styles.headerFindJob]: true
                })}
              >
                {whyBossJob || 'About'}
                <FindJobsSvg />
              </Text>
            </Dropdown>
          </li>
        </RIf>

        {overflowLinks.length > 0 ? (
          <li className={classNames([styles.headerLink])} style={{ paddingRight: 40 }}>
            <Dropdown
              menu={{ items: overflowLinks }}
              overlayClassName={classNames({
                [styles.findJobDownMenu]: true,
                [styles.moreLinks]: true
              })}
            >
              <span style={{ cursor: 'pointer' }}>
                <MoreSvg />
              </span>
            </Dropdown>
          </li>
        ) : null}
      </ul>
    </>
  )
}

export default NavLeft
